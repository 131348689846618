.widgets__horizontal-calendar {

  #hr-calendar .dropdown-content {
    width: 150px !important;
    max-width: 150px !important;
  }

  #hr-calendar .select-wrapper span.caret {
    padding-right: .3rem;
  }

  #hr-calendar > div,
  #hr-calendar ul > li {
    display: inline-block;
  }

  #hr-calendar {
    float: right;
    border: 2px solid #e2e2e2;
  }

  #hr-calendar > .head {
    text-align: center;
    padding: 9px 20px;
    background-color: #f5f6f6;
    color: #7a7b7b;
    font-size: 20px;
    float: left;
    border-right: 1px solid #e2e2e2;
  }

  #hr-calendar ul {
    margin: 0;
  }

  #hr-calendar ul li {
    padding: 2px 12px;
  }

  #hr-calendar ul li.active {
    border-bottom: 4px solid #f44336;
    color: #f44336;
  }

  #hr-calendar ul li.active p {
    color: #f44336;
  }

  #hr-calendar ul li p {
    margin: 0;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    color: #7a7b7b;
  }

  #hr-calendar ul li p:nth-child(1) {
    font-size: 14px;
  }

  #hr-calendar ul li p:nth-child(2) {
    font-size: 18px;
  }

  #hr-calendar > .prev,
  #hr-calendar > .next {
    cursor: pointer;
    color: #7a7b7b;
  }

  #hr-calendar > .prev > i,
  #hr-calendar > .next > i {
    font-size: 30px;
  }

  #hr-calendar > .prev {
    padding-left: 20px;
  }

  #hr-calendar > .next {
    padding-right: 20px;
  }

  #hr-calendar {
    float: none;
    text-align: center;
  }

  #hr-calendar ul li {
    padding: 2px .4rem;
  }

  #hr-calendar ul li p:nth-child(1) {
    font-size: 10px;
  }

  #hr-calendar ul li p:nth-child(2) {
    font-size: 12px;
  }

  #hr-calendar .prev {
    padding-left: 5px;
  }

  #hr-calendar .next {
    padding-right: 5px;
  }

  @media only screen and (min-width: 400px) {
    #hr-calendar ul li {
      padding: 2px .4rem;
    }
  }

  @media only screen and (min-width: 500px) {
    #hr-calendar ul li {
      padding: 2px .4rem;
    }
  }

  @media only screen and (min-width: 600px) {
    #hr-calendar ul li {
      padding: 2px 0.8rem;
    }

    #hr-calendar ul li p:nth-child(1) {
      font-size: 14px;
    }

    #hr-calendar ul li p:nth-child(2) {
      font-size: 18px;
    }
  }

  @media only screen and (min-width: 768px) {
    #hr-calendar ul li {
      padding: 2px 1.5rem;
    }
  }

  @media only screen and (min-width: 992px) {
    #hr-calendar {
      float: none;
      text-align: center;
    }

    #hr-calendar ul li {
      padding: 2px 0.5rem;
    }

    .hr-calendar.offset-l2 {
      margin-left: 23% !important;
    }

    .hr-calendar.l8 {
      width: 54% !important;
    }
  }

  @media only screen and (min-width: 1100px) {
    #hr-calendar ul li {
      padding: 2px 0.8rem;
    }
  }

  @media only screen and (min-width: 1200px) {
    #hr-calendar {
      float: right;
    }

    .hr-calendar.offset-l2 {
      margin-left: 22% !important;
    }

    .hr-calendar.l8 {
      width: 56% !important;
    }
  }

  @media only screen and (min-width: 1400px) {
    .hr-calendar.offset-l2 {
      margin-left: 20% !important;
    }

    .hr-calendar.l8 {
      width: 60% !important;
    }
  }

  @media only screen and (min-width: 1700px) {
    .hr-calendar.offset-l2 {
      margin-left: 16.6666666667% !important;
    }

    .hr-calendar.l8 {
      width: 66.6666666667% !important;
    }
  }

}
